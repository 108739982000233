import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import fields from '../../Common/constants/fields';
import {MODES} from '../../../../../contexts/mode.context';

const matcherEngineFieldKeyList = (mode) => [
  Fields.CARD_CATEGORY,
  Fields.CARD_TYPE,
  Fields.CASHIER_FILE_DATE,
  Fields.CASHIER_FILE_ID,
  Fields.CASHIER_FILE_NAME,
  Fields.CASHIER_PARTNER,
  Fields.CURRENCY,
  Fields.IS_RECONCILED,
  Fields.MERCHANT_ACCOUNT,
  Fields.MERCHANT_ACCOUNT_NAME,
  Fields.MERCHANT_CONTRACT,
  Fields.MERCHANT_CONTRACT_NAME,
  Fields.MERCHANT_STORE_ID,
  Fields.MERCHANT_TERMINAL_ID,
  Fields.ORDER_AMOUNT,
  Fields.ORDER_CREATION_DATE,
  Fields.ORDER_ID,
  Fields.ORDER_IS_RECONCILED,
  Fields.ORDER_MERCHANT_ID,
  Fields.ORDER_RECONCILED_AMOUNT,
  Fields.ORDER_STATUS,
  Fields.ORDER_TRANSACTIONS_COUNT,
  Fields.ORDER_UNRECONCILED_AMOUNT,
  Fields.PAYIN_PARTNER,
  Fields.PAYMENT_CHANNEL,
  Fields.PAYMENT_METHOD,
  Fields.PAYMENT_METHOD_TYPE,
  Fields.PSP_TRANSACTION_DATE,
  Fields.PSP_TRANSACTION_ID,
  Fields.RECONCILED_AMOUNT,
  Fields.RECONCILIATION_AUTHOR_EMAIL,
  Fields.RECONCILIATION_DATE,
  Fields.RECONCILIATION_NOTE,
  Fields.RECONCILIATION_REASON,
  Fields.RECONCILIATION_TYPE,
  Fields.SOURCE,
  Fields.TRANSACTION_AMOUNT,
  Fields.TRANSACTION_CREATION_DATE,
  Fields.TRANSACTION_ID,
  Fields.TRANSACTION_STATUS,
  Fields.TRANSACTION_UPDATE_DATE,
  Fields.UNRECONCILED_AMOUNT,
  Fields.UPDATE_DATE,
  Fields.WITHDRAW_REASON,
  ...(mode === MODES.INTERNAL ? [Fields.META_PROGRAM_MANAGER] : []),
  ...([MODES.INTERNAL, MODES.META_PM].includes(mode) ? [Fields.PROGRAM_MANAGER] : []),
  ...([MODES.INTERNAL, MODES.META_PM, MODES.PM].includes(mode) ? [Fields.COMPANY] : []),
];

export default (mode) => matcherEngineFieldKeyList(mode).reduce((memo, key) => ({ ...memo, [key]: fields[key] }), {});
