import React, {useContext, useEffect, useState} from 'react';
import { gql, useQuery } from '@apollo/client';
import { applyTheme, defaultTheme } from '../util/theme';
import {useMetaProgramManagerId, useProgramManagerId} from './app.context';
import AppLoading from '../AppLoading';
import domain from '../constants/domain';
import {MetaProgramManagerContext} from './metaProgramManager.context';
import {ModeContext, MODES} from './mode.context';

const programManagerDomainQuery = gql`
  query ProgramManagerDomain($domain: String!) {
    programManagerDomain(domain: $domain) {
      id
      displayName
      website
      merchantDomain
      adminDomain
      alternativeAdminDomain
      alternativeMerchantDomain
      theme {
        favicon
        icon
        logo
        background
        primaryColor
        secondaryColor
        loader {
          img
          type
          animation
        }
      }
      loginMethod
      sso {
        config {
          tenantId
          providerId
        }
      }
      metaProgramManager {
        id
        displayName
        theme {
          favicon
          icon
          logo
          background
          primaryColor
          secondaryColor
          loader {
            img
            type
            animation
          }
        }
      }
    }
    metaProgramManagerDomain(domain: $domain) {
      id
      displayName
      website
      merchantDomain
      adminDomain
      alternativeAdminDomain
      alternativeMerchantDomain
      theme {
          favicon
          icon
          logo
          background
          primaryColor
          secondaryColor
          loader {
              img
              type
              animation
          }
      }
      loginMethod
      sso {
          config {
              tenantId
              providerId
          }
      }
    }
  }
`;

export const ProgramManagerContext = React.createContext(undefined);

export const useProgramManager = () => useContext(ProgramManagerContext);

const ProgramManagerProvider = ({ children }) => {
  const [programManagerId, setProgramManagerId] = useProgramManagerId();
  const [metaProgramManagerId, setMetaProgramManagerId] = useMetaProgramManagerId();
  const [mode, setMode] = useState(domain ? null : MODES.INTERNAL);

  const { data } = useQuery(programManagerDomainQuery, {
    // override Authorization header to always request as unlogged user
    context: {
      headers: {
        Authorization: undefined,
      },
    },
    variables: { domain },
    skip: !domain,
    onCompleted: (res) => {
      const result = res.programManagerDomain ?? res.metaProgramManagerDomain;
      if (res.programManagerDomain) {
        setProgramManagerId(res.programManagerDomain?.id);
        if (domain === res.programManagerDomain.merchantDomain){
          setMode(MODES.MERCHANT);
        } else if (domain === res.programManagerDomain.adminDomain) {
          setMode(MODES.PM);
        } else {
          console.error('error with domain');
        }
      }
      if (res.metaProgramManagerDomain) {
        setMetaProgramManagerId(res.metaProgramManagerDomain?.id);
        setMode(MODES.META_PM)
      }
      document.title = result?.displayName;
      if (result?.theme?.favicon) {
        const favicon = document.getElementById('favicon');
        favicon.href = result?.theme?.favicon;
      }
      applyTheme({
        primaryColor: result?.theme.primaryColor || defaultTheme.primaryColor,
        secondaryColor: result?.theme.secondaryColor || defaultTheme.secondaryColor,
        loader: result?.theme?.loader,
      });
    },
  });

  useEffect(() => {
    if (!domain) {
      document.title = 'NORBr';
      applyTheme();
    }
  }, []);

  if (domain && !programManagerId && !metaProgramManagerId) return <AppLoading />;

  return (
    <ProgramManagerContext.Provider value={data?.programManagerDomain}>
      <MetaProgramManagerContext.Provider
        value={data?.metaProgramManagerDomain}
      >
        <ModeContext.Provider
          value={mode}
        >
          {children}
        </ModeContext.Provider>
      </MetaProgramManagerContext.Provider>
    </ProgramManagerContext.Provider>
  );
};

export default ProgramManagerProvider;
