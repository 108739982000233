import ShoppingIcon from '@2fd/ant-design-icons/lib/Shopping';
import BookmarkIcon from '@2fd/ant-design-icons/lib/Bookmark';
import CurrencyEurIcon from '@2fd/ant-design-icons/lib/CurrencyEur';
import BellIcon from '@2fd/ant-design-icons/lib/Bell';
import ViewListOutlineIcon from '@2fd/ant-design-icons/lib/ViewListOutline';
import CartIcon from '@2fd/ant-design-icons/lib/Cart';
import ViewCarouselIcon from '@2fd/ant-design-icons/lib/ViewCarousel';
import DomainIcon from '@2fd/ant-design-icons/lib/Domain';
import AccountMultipleIcon from '@2fd/ant-design-icons/lib/AccountMultiple';
import FileDocumentEditIcon from '@2fd/ant-design-icons/lib/FileDocumentEdit';
import GroupIcon from '@2fd/ant-design-icons/lib/Group';
import { ApartmentOutlined, PartitionOutlined } from '@ant-design/icons';

export const TargetEntity = {
  ORDER: 'order',
  CHECKOUT: 'checkout',
  TRANSACTION: 'transactions',
  FINANCIAL_OPERATION: 'financial_operations',
  NOTIFICATION: 'notifications',
  LOG: 'log',
  PROGRAM_MANAGER: 'program_manager',
  META_PROGRAM_MANAGER: 'meta_program_manager',
  MERCHANT_ACCOUNT: 'merchant_account',
  MERCHANT_GROUP: 'merchant_group',
  MERCHANT_COMPANY: 'merchant_company',
  MERCHANT_USER: 'merchant_user',
  MERCHANT_CONTRACT: 'merchant_contract',
  PROGRAM_MANAGER_USER: 'program_manager_user',
  META_PROGRAM_MANAGER_USER: 'meta_program_manager_user',
  INTERNAL_USER: 'internal_user',
};

const targetEntities = {
  [TargetEntity.ORDER]: { value: TargetEntity.ORDER, label: 'Order', icon: ShoppingIcon },
  [TargetEntity.CHECKOUT]: {
    value: TargetEntity.CHECKOUT,
    label: 'Checkout',
    icon: CartIcon,
  },
  [TargetEntity.TRANSACTION]: {
    value: TargetEntity.TRANSACTION,
    label: 'Transaction',
    icon: BookmarkIcon,
  },
  [TargetEntity.FINANCIAL_OPERATION]: {
    value: TargetEntity.FINANCIAL_OPERATION,
    label: 'Finance',
    icon: CurrencyEurIcon,
  },
  [TargetEntity.NOTIFICATION]: {
    value: TargetEntity.NOTIFICATION,
    label: 'Notification',
    icon: BellIcon,
  },
  [TargetEntity.LOG]: {
    value: TargetEntity.LOG,
    label: 'Log',
    icon: ViewListOutlineIcon,
  },
  [TargetEntity.PROGRAM_MANAGER]: {
    value: TargetEntity.PROGRAM_MANAGER,
    label: 'Program manager',
    icon: ApartmentOutlined,
  },
  [TargetEntity.PROGRAM_MANAGER]: {
    value: TargetEntity.META_PROGRAM_MANAGER,
    label: 'Meta program manager',
    icon: PartitionOutlined,
  },
  [TargetEntity.MERCHANT_ACCOUNT]: {
    value: TargetEntity.MERCHANT_ACCOUNT,
    label: 'Merchant account',
    icon: ViewCarouselIcon,
  },
  [TargetEntity.MERCHANT_GROUP]: {
    value: TargetEntity.MERCHANT_GROUP,
    label: 'Group',
    icon: GroupIcon,
  },
  [TargetEntity.MERCHANT_COMPANY]: {
    value: TargetEntity.MERCHANT_COMPANY,
    label: 'Company',
    icon: DomainIcon,
  },
  [TargetEntity.MERCHANT_USER]: {
    value: TargetEntity.MERCHANT_USER,
    label: 'User',
    icon: AccountMultipleIcon,
  },
  [TargetEntity.MERCHANT_CONTRACT]: {
    value: TargetEntity.MERCHANT_CONTRACT,
    label: 'Merchant contract',
    icon: FileDocumentEditIcon,
  },
  [TargetEntity.PROGRAM_MANAGER_USER]: {
    value: TargetEntity.PROGRAM_MANAGER_USER,
    label: 'Program manager user',
    icon: AccountMultipleIcon,
  },
  [TargetEntity.META_PROGRAM_MANAGER_USER]: {
    value: TargetEntity.META_PROGRAM_MANAGER_USER,
    label: 'Meta program manager user',
    icon: AccountMultipleIcon,
  },
  [TargetEntity.INTERNAL_USER]: {
    value: TargetEntity.INTERNAL_USER,
    label: 'Internal user',
    icon: AccountMultipleIcon,
  },
};

export const targetEntityList = Object.values(targetEntities);

export default targetEntities;
