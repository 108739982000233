import React, {useCallback, useContext, useEffect} from 'react';
import { Select } from 'antd';
import { useQuery as useApolloQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import styles from './MerchantAccountSelector.module.scss';
import merchantAccountsQuery from './query';
import { useMe } from '../../../../contexts/me.context';
import { useMerchantAccount } from '../merchantAccount.context';
import SOURCES from '../../../../constants/SOURCES';
import {ContractEditionContext} from '../PaymentNetwork/PaymentNetwork';

const MerchantAccountSelector = React.memo(({ stackOnly }) => {
  const me = useMe();
  const { merchantAccount } = useMerchantAccount();
  const { setIsEdited, setEditedContractId } = useContext(ContractEditionContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = useCallback(
    (value) => {
      setIsEdited(false);
      setEditedContractId(null);
      searchParams.delete('pn');
      searchParams.set('maccount', value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    if (stackOnly && merchantAccount && merchantAccount.source !== SOURCES.STACK) {
      searchParams.delete('maccount');
      setSearchParams(searchParams);
    }
  }, [stackOnly, merchantAccount]);

  const { data, loading } = useApolloQuery(merchantAccountsQuery, {
    variables: { company: me.companies?.[0]?.company.id },
    onCompleted: (res) => {
      if (res.merchantAccounts.length === 1) {
        handleChange(res.merchantAccounts[0].id);
      }
    },
  });

  return (
    <div className={styles.root}>
      <Select
        className={styles.select}
        placeholder="Merchant Account"
        loading={loading}
        value={searchParams.get('maccount')}
        onChange={handleChange}
      >
        {[...(data?.merchantAccounts ?? [])]
          ?.sort((a, b) => (b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1))
          ?.filter((merchantAccountElem) => (stackOnly ? merchantAccountElem?.source === SOURCES.STACK : true))
          ?.map((merchantAccountElem) => (
            <Select.Option key={merchantAccountElem.id} value={merchantAccountElem.id}>
              {merchantAccountElem.name}
            </Select.Option>
          ))}
      </Select>
    </div>
  );
});

export default MerchantAccountSelector;
