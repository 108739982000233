import React from 'react';
import { gql } from '@apollo/client';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import AUTHENTICATION_REQUEST_STATUS_ENUM_LIST from 'norbr-shared-lib/constants/transaction/authenticationRequestStatus';
import AUTHORIZATION_REQUEST_STATUS_ENUM_LIST from 'norbr-shared-lib/constants/transaction/authorizationRequestStatus';
import AUTHORIZATION_STATUS_ENUM_LIST from 'norbr-shared-lib/constants/transaction/authorizationStatus';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import CURRENCY_LIST from 'norbr-shared-lib/constants/currencies/list';
import CANCELLATION_REQUEST_STATUS_ENUM_LIST from 'norbr-shared-lib/constants/transaction/cancellationRequestStatus';
import CANCELLATION_STATUS_ENUM_LIST from 'norbr-shared-lib/constants/transaction/cancellationStatus';
import CAPTURE_REQUEST_STATUS_ENUM_LIST from 'norbr-shared-lib/constants/transaction/captureRequestStatus';
import CAPTURE_STATUS_ENUM_LIST from 'norbr-shared-lib/constants/transaction/captureStatus';
import DEVICE_TYPES from 'norbr-shared-lib/constants/transaction/deviceTypes';
import DEVICE_TYPE_LIST from 'norbr-shared-lib/constants/transaction/deviceTypes/list';
import PAYMENT_METHOD_TYPES from 'norbr-shared-lib/constants/paymentMethods/paymentMethodTypes';
import PAYMENT_METHOD_TYPE_LIST from 'norbr-shared-lib/constants/paymentMethods/paymentMethodTypes/list';
import REFUND_REASONS from 'norbr-shared-lib/constants/transaction/refundReasons';
import REFUND_REASON_LIST from 'norbr-shared-lib/constants/transaction/refundReasons/list';
import REFUND_STATUS_ENUM_LIST from 'norbr-shared-lib/constants/transaction/refundStatus';
import REFUND_REQUEST_STATUS_ENUM_LIST from 'norbr-shared-lib/constants/transaction/refundRequestStatus';
import SCA_EXEMPTION_REASONS from 'norbr-shared-lib/constants/strongCustomerAuthentication/exemptionReasons';
import SCA_EXEMPTION_REASON_LIST from 'norbr-shared-lib/constants/strongCustomerAuthentication/exemptionReasons/list';
import SHIPPING_DELIVERY_METHODS from 'norbr-shared-lib/constants/order/shippingDeliveryMethods';
import SHIPPING_DELIVERY_METHOD_LIST from 'norbr-shared-lib/constants/order/shippingDeliveryMethods/list';
import TRANSACTION_TYPES from 'norbr-shared-lib/constants/transaction/types';
import TRANSACTION_TYPE_LIST from 'norbr-shared-lib/constants/transaction/types/list';
import FINANCIAL_OPERATION_TYPES from 'norbr-shared-lib/constants/reconciliation/financialOperationTypes';
import FINANCIAL_OPERATION_TYPE_LIST from 'norbr-shared-lib/constants/reconciliation/financialOperationTypes/list';
import FINANCIAL_OPERATION_FEE_TYPES from 'norbr-shared-lib/constants/reconciliation/feeTypes';
import FINANCIAL_OPERATION_FEE_TYPE_LIST from 'norbr-shared-lib/constants/reconciliation/feeTypes/list';
import FINANCIAL_OPERATION_MOVEMENTS from 'norbr-shared-lib/constants/reconciliation/financialOperationMovements';
import FINANCIAL_OPERATION_MOVEMENT_LIST from 'norbr-shared-lib/constants/reconciliation/financialOperationMovements/list';
import OPERATION_TYPES from 'norbr-shared-lib/constants/reconciliation/operationTypes';
import OPERATION_TYPE_LIST from 'norbr-shared-lib/constants/reconciliation/operationTypes/list';
import PARTNER_TYPES from 'norbr-shared-lib/constants/reconciliation/partnerTypes';
import PARTNER_TYPE_LIST from 'norbr-shared-lib/constants/reconciliation/partnerTypes/list';
import CUSTOMER_ACCOUNT_LOGIN_TYPES from 'norbr-shared-lib/constants/customerAccountLoginTypes/customerAccountLoginTypes';
import CUSTOMER_ACCOUNT_LOGIN_TYPE_LIST from 'norbr-shared-lib/constants/customerAccountLoginTypes/list';
import CHALLENGE_PREFERENCES from 'norbr-shared-lib/constants/strongCustomerAuthentication/challengePreferences/challengePreferences';
import CHALLENGE_PREFERENCE_LIST from 'norbr-shared-lib/constants/strongCustomerAuthentication/challengePreferences/list';
import NOTIFICATION_STATUSES from 'norbr-shared-lib/constants/notification/status';
import NOTIFICATION_STATUS_LIST from 'norbr-shared-lib/constants/notification/status/list';
import RECONCILIATION_REASONS from 'norbr-shared-lib/constants/transaction/reconciliationReasons';
import WITHDRAW_REASONS from 'norbr-shared-lib/constants/transaction/withdrawReasons';
import MATCH_LATER_REASONS from 'norbr-shared-lib/constants/transaction/matchLaterReasons';
import RECONCILIATION_TYPES from 'norbr-shared-lib/constants/transaction/reconciliationTypes';
import RECONCILIATION_TYPE_LIST from 'norbr-shared-lib/constants/transaction/reconciliationTypes/list';
import INTEGRATION_TYPES from 'norbr-shared-lib/constants/merchantAccounts/integrationTypes';
import INTEGRATION_TYPE_LIST from 'norbr-shared-lib/constants/merchantAccounts/integrationTypes/list';

import fields from 'norbr-shared-lib/constants/order/fields';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import FieldList from 'norbr-shared-lib/constants/order/fields/enumList';
import SharedFieldTypes from 'norbr-shared-lib/constants/order/fields/fieldTypes';
import AUTHENTICATION_RESULTS, { AUTHENTICATION_RESULT_LIST } from '../../../../../constants/AUTHENTICATION_RESULTS';
import COUNTRIES, { TREE_COUNTRY_LIST } from '../../../../../constants/COUNTRIES';
import PAYMENT_CHANNELS, { PAYMENT_CHANNEL_LIST } from '../../../../../constants/PAYMENT_CHANNELS';
import TRANSACTION_STATUSES, { TRANSACTION_STATUS_LIST } from '../../../../../constants/TRANSACTION_STATUSES';
import STATUS_RESULTS, { STATUS_RESULT_LIST } from '../../../../../constants/STATUS_RESULTS';
import { DATETIME_FORMAT } from '../../../../../constants/DATES';
import { isModeAdmin } from '../../../../../constants/mode';
import FieldTypes from './fieldTypes';
import DateFormats from '../../../../../constants/dateFormats';
import AmountFormats from '../../../../../constants/amountFormats';
import NumberFormats from '../../../../../constants/numberFormats';
import { SOURCES, SOURCE_LIST } from '../../../../../constants/SOURCES';
import { config as amountRanges } from '../../Dashboard/constants/tables/amount_ranges';
import UpdateCell from '../../../LogList/LogTable/UpdateCell/UpdateCell';
import { getLinkTo } from '../../../LogList/LogTable/UpdateCell/utils';
import CHECKOUT_TYPES from '../../../../../constants/CHECKOUT_TYPES';
import CHECKOUT_TOKEN_TYPES from '../../../../../constants/CHECKOUT_TOKEN_TYPES';
import { MODES } from '../../../../../contexts/mode.context';

const paymentMethodsQuery = gql`
  {
    partners {
      name
      payment_methods {
        id
        api_name
        name
        imgUrl
      }
    }
  }
`;

const partnersQuery = gql`
  {
    partners {
      id
      api_name
      name
      type
      company {
        theme {
          icon
          primaryColor
        }
      }
      risk_assessment_service
      support_authentication
    }
  }
`;

const merchantAccountsQuery = gql`
  query MerchantAccounts {
    merchantAccounts {
      id
      name
      company {
        theme {
          icon
          primaryColor
        }
      }
    }
  }
`;

const merchantCompaniesQuery = gql`
  query MerchantCompanies {
    merchantCompanies {
      id
      name
      theme {
        icon
        primaryColor
        secondaryColor
      }
    }
  }
`;

const programManagersQuery = gql`
  query ProgramManagers {
    programManagers {
      id
      name
      theme {
        icon
        primaryColor
        secondaryColor
      }
    }
  }
`;

const metaProgramManagersQuery = gql`
  query MetaProgramManagers {
    metaProgramManagers {
      id
      name
      theme {
        icon
        primaryColor
        secondaryColor
      }
    }
  }
`;

const merchantContractsQuery = gql`
  query MerchantContracts {
    merchantAccounts {
      company {
        theme {
          icon
          primaryColor
        }
      }
      merchant_contracts(filters: { status: { in: ["active", "inactive"] } }) {
        id
        name
      }
    }
  }
`;

const webhooksQuery = gql`
  query Webhooks {
    webhooks {
      id
      name
    }
  }
`;

/**
 * Field (TODO - match Transaction properties from norbr-shared-lib ??)
 * Describe transaction / order field, filter & display
 * A field is an object relative to a property of requested entity which is filterable.
 * ie:
 * - key : enum Fields (shared-lib)
 * - label : String
 * - type : enum FieldTypes
 * - displayOptions
 *     - getOption : (value) => Option
 *     - avatar : Bool
 *     - image : Bool
 *     - icon : Bool
 *     - hideLabel : Bool
 *     - currency : enum Fields
 *     - ellipsis: Bool | String | enum
 *     - …
 * - filterOptions
 *     - options : [Option]
 *     - asyncOptions
 *         - query : Query
 *         - formatData : [data] => [Option]
 *     - valueType : enum ValueTypes
 *     - defaultValue : any
 * - defaultSettings : Object
 * - gdpr : Bool (TODO : user restrictions)
 * - scope : enum Scopes (TODO : merchant / admin restrictions)
 *
 * Option:
 * - value : String
 * - label : String
 * - img : String?
 * - icon : Icon?
 * - color : String?
 * - ...
 */
export const fieldTypes = {
  [SharedFieldTypes.TEXT]: {
    type: FieldTypes.TEXT,
    filterOptions: { defaultValue: [] },
    displayOptions: {},
  },
  [SharedFieldTypes.AMOUNT]: {
    type: FieldTypes.AMOUNT,
    filterOptions: {
      defaultValue: null,
    },
    displayOptions: {
      currency: Fields.CURRENCY, // Fields.CURRENCY
    },
    defaultSettings: {
      format: AmountFormats.FR_CURRENCY,
    },
  },
  [SharedFieldTypes.NUMBER]: {
    type: FieldTypes.NUMBER,
    filterOptions: {
      defaultValue: null,
    },
    displayOptions: {},
    defaultSettings: {
      format: NumberFormats.FR,
    },
  },
  [SharedFieldTypes.DATE]: {
    type: FieldTypes.DATE,
    filterOptions: {
      defaultValue: {
        from: moment().startOf('day').format(DATETIME_FORMAT),
        to: moment().endOf('day').format(DATETIME_FORMAT),
        period: 'today',
      },
    },
    displayOptions: {},
    defaultSettings: {
      format: DateFormats.DATETIME,
    },
  },
  [SharedFieldTypes.COUNTRY]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: TREE_COUNTRY_LIST,
      defaultValue: [],
      treeSelect: true,
    },
    displayOptions: {
      getOption: (value) => COUNTRIES[value],
      avatar: true,
      hideLabel: true,
    },
  },
  [SharedFieldTypes.PAYMENT_CHANNEL]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: PAYMENT_CHANNEL_LIST,
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => PAYMENT_CHANNELS[value],
      icon: true,
    },
  },
  [SharedFieldTypes.PAYMENT_METHOD]: {
    type: FieldTypes.ASYNC_LIST,
    filterOptions: {
      asyncOptions: {
        query: paymentMethodsQuery,
        formatData: (data) =>
          data?.partners
            ?.flatMap((p) => p.payment_methods)
            // dedupe
            ?.filter((pm, index, list) => list.findIndex((elem) => pm.api_name === elem.api_name) === index)
            ?.map((paymentMethod) => ({
              value: paymentMethod.api_name,
              label: paymentMethod.name,
              img: paymentMethod.imgUrl,
            })),
        defaultValue: [],
      },
    },
    displayOptions: {
      image: true,
      hideLabel: true,
    },
  },
  [SharedFieldTypes.ID]: {
    type: FieldTypes.TEXT,
    filterOptions: { defaultValue: [] },
    displayOptions: {
      ellipsis: 'middle',
    },
  },
  [SharedFieldTypes.OBJECT_ID]: {
    type: FieldTypes.TEXT,
    filterOptions: { defaultValue: [] },
    displayOptions: {
      ellipsis: 'middle',
    },
  },
  [SharedFieldTypes.PAYIN_PARTNER]: {
    type: FieldTypes.ASYNC_LIST,
    filterOptions: {
      asyncOptions: {
        query: partnersQuery,
        formatData: (data) =>
          data?.partners
            ?.filter((partner) => partner.type === 'payin')
            ?.map((partner) => ({
              value: partner.api_name,
              label: partner.name,
              img: partner.company.theme.icon,
              color: partner.company.theme.primaryColor,
            })),
      },
      defaultValue: [],
    },
    displayOptions: {
      avatar: true,
      avatarShape: 'square',
      hideLabel: true,
    },
  },
  [SharedFieldTypes.CASHIER_PARTNER]: {
    type: FieldTypes.ASYNC_LIST,
    filterOptions: {
      asyncOptions: {
        query: partnersQuery,
        formatData: (data) =>
          data?.partners
            ?.filter((partner) => partner.type === 'cashier')
            ?.map((partner) => ({
              value: partner.api_name,
              label: partner.name,
              img: partner.company.theme.icon,
              color: partner.company.theme.primaryColor,
            })),
      },
      defaultValue: [],
    },
    displayOptions: {
      avatar: true,
      avatarShape: 'square',
      hideLabel: true,
    },
  },
  [SharedFieldTypes.CURRENCY]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: CURRENCY_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => CURRENCIES[value],
    },
  },
  [SharedFieldTypes.STATUS]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: TRANSACTION_STATUS_LIST,
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => TRANSACTION_STATUSES[value],
      tag: true,
      icon: true,
    },
  },
  [SharedFieldTypes.AUTHENTICATION_RESULT]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: AUTHENTICATION_RESULT_LIST,
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => AUTHENTICATION_RESULTS[value],
      icon: true,
      hideLabel: true,
    },
  },
  [SharedFieldTypes.AUTHENTICATION_PARTNER]: {
    type: FieldTypes.ASYNC_LIST,
    filterOptions: {
      asyncOptions: {
        query: partnersQuery,
        formatData: (data) =>
          data?.partners
            ?.filter((partner) => partner.type === 'authentication' || partner.support_authentication)
            ?.map((partner) => ({
              value: partner.api_name,
              label: partner.name,
              img: partner.company.theme.icon,
              color: partner.company.theme.primaryColor,
            })),
      },
      defaultValue: [],
    },
    displayOptions: {
      avatar: true,
      avatarShape: 'square',
      hideLabel: true,
    },
  },
  [SharedFieldTypes.RISK_ASSESSMENT_PARTNER]: {
    type: FieldTypes.ASYNC_LIST,
    filterOptions: {
      asyncOptions: {
        query: partnersQuery,
        formatData: (data) =>
          data?.partners
            ?.filter(
              (partner) =>
                partner.type === 'risk_assessment' ||
                partner.risk_assessment_service === 'embedded_deactivable' ||
                partner.risk_assessment_service === 'embedded_not_deactivable',
            )
            ?.map((partner) => ({
              value: partner.api_name,
              label: partner.name,
              img: partner.company.theme.icon,
              color: partner.company.theme.primaryColor,
            })),
      },
      defaultValue: [],
    },
    displayOptions: {
      avatar: true,
      avatarShape: 'square',
      hideLabel: true,
    },
  },
  [SharedFieldTypes.BOOLEAN]: {
    type: FieldTypes.BOOLEAN,
    filterOptions: {
      defaultValue: [true],
    },
    displayOptions: {
      getOption: (v) =>
        ({
          [true]: { label: 'Yes' },
          [false]: { label: 'No' },
        })[v],
      // trueElement: 'True', // default "Yes"
      // falseElement: 'False', // default "No"
    },
  },
  [SharedFieldTypes.COUNT]: {
    type: FieldTypes.COUNT,
    filterOptions: {
      defaultValue: 0,
    },
    displayOptions: {},
  },
  [SharedFieldTypes.AUTHORIZATION_CODE]: {
    type: FieldTypes.TEXT,
    filterOptions: { defaultValue: [] },
    displayOptions: {},
  },
  [SharedFieldTypes.AUTHENTICATION_REQUEST_STATUS]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: AUTHENTICATION_REQUEST_STATUS_ENUM_LIST.map((s) => TRANSACTION_STATUSES[s]),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => TRANSACTION_STATUSES[value],
      tag: true,
    },
  },
  [SharedFieldTypes.AUTHORIZATION_REQUEST_STATUS]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: AUTHORIZATION_REQUEST_STATUS_ENUM_LIST.map((s) => TRANSACTION_STATUSES[s]),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => TRANSACTION_STATUSES[value],
      tag: true,
    },
  },
  [SharedFieldTypes.AUTHORIZATION_STATUS]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: AUTHORIZATION_STATUS_ENUM_LIST.map((s) => TRANSACTION_STATUSES[s]),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => TRANSACTION_STATUSES[value],
      tag: true,
    },
  },
  [SharedFieldTypes.CANCELLATION_REQUEST_STATUS]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: CANCELLATION_REQUEST_STATUS_ENUM_LIST.map((s) => TRANSACTION_STATUSES[s]),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => TRANSACTION_STATUSES[value],
      tag: true,
    },
  },
  [SharedFieldTypes.CANCELLATION_STATUS]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: CANCELLATION_STATUS_ENUM_LIST.map((s) => TRANSACTION_STATUSES[s]),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => TRANSACTION_STATUSES[value],
      tag: true,
    },
  },
  [SharedFieldTypes.CAPTURE_REQUEST_STATUS]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: CAPTURE_REQUEST_STATUS_ENUM_LIST.map((s) => TRANSACTION_STATUSES[s]),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => TRANSACTION_STATUSES[value],
      tag: true,
    },
  },
  [SharedFieldTypes.CAPTURE_STATUS]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: CAPTURE_STATUS_ENUM_LIST.map((s) => TRANSACTION_STATUSES[s]),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => TRANSACTION_STATUSES[value],
      tag: true,
    },
  },
  [SharedFieldTypes.DEVICE_TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: DEVICE_TYPE_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => DEVICE_TYPES[value],
    },
  },
  [SharedFieldTypes.CHARGEBACK_REASON]: {
    type: FieldTypes.TEXT, // TODO - enum list ?
    filterOptions: { defaultValue: [] },
    displayOptions: {},
  },
  [SharedFieldTypes.CHARGEBACK_REASON_CODE]: {
    type: FieldTypes.TEXT, // TODO - enum list ?
    filterOptions: { defaultValue: [] },
    displayOptions: {
      tag: true,
    },
  },
  [SharedFieldTypes.RISK_ASSESSMENT_REASON]: {
    type: FieldTypes.TEXT, // TODO - enum list ?
    filterOptions: { defaultValue: [] },
    displayOptions: {},
  },
  [SharedFieldTypes.RISK_ASSESSMENT_REASON_CODE]: {
    type: FieldTypes.TEXT, // TODO - enum list ?
    filterOptions: { defaultValue: [] },
    displayOptions: {
      tag: true,
    },
  },
  [SharedFieldTypes.BASKET_TYPE]: {
    type: FieldTypes.TEXT, // TODO - enum list ?
    filterOptions: { defaultValue: [] },
    displayOptions: {
      tag: true,
    },
  },
  [SharedFieldTypes.REFUND_REASON]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: REFUND_REASON_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => REFUND_REASONS[value],
    },
  },
  [SharedFieldTypes.REFUND_STATUS]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: REFUND_STATUS_ENUM_LIST.map((s) => TRANSACTION_STATUSES[s]),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => TRANSACTION_STATUSES[value],
      tag: true,
    },
  },
  [SharedFieldTypes.STATUS_RESULT]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: STATUS_RESULT_LIST,
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => STATUS_RESULTS[value],
      tag: true,
    },
  },
  [SharedFieldTypes.MERCHANT_ACCOUNT]: {
    type: FieldTypes.ASYNC_LIST,
    filterOptions: {
      asyncOptions: {
        query: merchantAccountsQuery,
        formatData: (data) =>
          data?.merchantAccounts?.map((ma) => ({
            value: ma.id,
            label: ma.name,
            img: ma.company?.theme.icon,
            color: ma.company?.theme.primaryColor,
          })),
      },
      defaultValue: [],
      nullable: false,
    },
    displayOptions: {
      // asId: true,
      // ellipsis: 'middle',
    },
  },
  [SharedFieldTypes.MERCHANT_CONTRACT]: {
    type: FieldTypes.ASYNC_LIST,
    filterOptions: {
      asyncOptions: {
        query: merchantContractsQuery,
        formatData: (data) => [
          {
            value: 'null',
            label: 'None',
          },
          ...(data?.merchantAccounts?.flatMap((ma) =>
            ma.merchant_contracts.map((mc) => ({
              value: mc.id,
              // If the merchant account has no name, we show the ID instead.
              label: mc.name === '' ? mc.id : mc.name,
              img: isModeAdmin && ma.company?.theme.icon,
            })),
          ) ?? []),
        ],
      },
      defaultValue: [],
    },
    displayOptions: {
      asId: true,
      ellipsis: 'middle',
    },
  },
  [SharedFieldTypes.SCA_EXEMPTION_REASON]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: SCA_EXEMPTION_REASON_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => SCA_EXEMPTION_REASONS[value],
    },
  },
  [SharedFieldTypes.PAYMENT_METHOD_TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: PAYMENT_METHOD_TYPE_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => PAYMENT_METHOD_TYPES[value],
    },
  },
  [SharedFieldTypes.PSP_RESULT_CODE]: {
    type: FieldTypes.TEXT, // TODO - enum list ?
    filterOptions: { defaultValue: [] },
    displayOptions: {
      tag: true,
    },
  },
  [SharedFieldTypes.SHIPPING_DELIVERY_METHOD]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: SHIPPING_DELIVERY_METHOD_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => SHIPPING_DELIVERY_METHODS[value],
    },
  },
  [SharedFieldTypes.MERCHANT_DATA]: {
    type: FieldTypes.CUSTOM,
    filterOptions: {},
    displayOptions: {
      customRender: (v) => v,
    },
  },
  [SharedFieldTypes.TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: TRANSACTION_TYPE_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => TRANSACTION_TYPES[value],
    },
  },
  [SharedFieldTypes.REFUND_REQUEST_STATUS]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: REFUND_REQUEST_STATUS_ENUM_LIST.map((s) => TRANSACTION_STATUSES[s]),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => TRANSACTION_STATUSES[value],
      tag: true,
    },
  },
  [SharedFieldTypes.SOURCE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: SOURCE_LIST,
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => SOURCES[value],
      tag: true,
      active: true,
    },
  },
  // Handle CARD_CATEGORY as TEXT
  [SharedFieldTypes.CARD_CATEGORY]: {
    type: FieldTypes.TEXT,
    filterOptions: { defaultValue: [] },
    displayOptions: {},
  },
  // [SharedFieldTypes.CARD_CATEGORY]: {
  //   type: FieldTypes.LIST,
  //   filterOptions: {
  //     options: CARD_CATEGORY_LIST.map(({ id, label }) => ({ value: id, label })),
  //     defaultValue: [],
  //   },
  //   displayOptions: {
  //     getOption: (value) => CARD_CATEGORIES[value],
  //     tag: true,
  //   },
  // },
  [SharedFieldTypes.FINANCIAL_OPERATION_TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: FINANCIAL_OPERATION_TYPE_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => FINANCIAL_OPERATION_TYPES[value],
    },
  },
  [SharedFieldTypes.FINANCIAL_OPERATION_FEE_TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: FINANCIAL_OPERATION_FEE_TYPE_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => FINANCIAL_OPERATION_FEE_TYPES[value],
    },
  },
  [SharedFieldTypes.FINANCIAL_OPERATION_MOVEMENT]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: FINANCIAL_OPERATION_MOVEMENT_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => FINANCIAL_OPERATION_MOVEMENTS[value],
    },
  },
  [SharedFieldTypes.OPERATION_TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: OPERATION_TYPE_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => OPERATION_TYPES[value],
    },
  },
  // Handle CARD_TYPE as TEXT
  [SharedFieldTypes.CARD_TYPE]: {
    type: FieldTypes.TEXT,
    filterOptions: { defaultValue: [] },
    displayOptions: {},
  },
  // [SharedFieldTypes.CARD_TYPE]: {
  //   type: FieldTypes.LIST,
  //   filterOptions: {
  //     options: CARD_TYPE_LIST.map(({ id, label }) => ({ value: id, label })),
  //     defaultValue: [],
  //   },
  //   displayOptions: {
  //     getOption: (value) => CARD_TYPES[value],
  //   },
  // },
  [SharedFieldTypes.ENVIRONMENT]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: [
        { value: 'dev', label: 'Development' },
        { value: 'staging', label: 'Staging' },
        { value: 'sandbox', label: 'Sandbox' },
        { value: 'production', label: 'Production' },
      ],
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) =>
        ({
          dev: { label: 'Development' },
          staging: { label: 'Staging' },
          sandbox: { label: 'Sandbox' },
          production: { label: 'Production' },
        })[value],
    },
  },
  [SharedFieldTypes.PARTNER_TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: PARTNER_TYPE_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => PARTNER_TYPES[value],
    },
  },
  [SharedFieldTypes.CUSTOMER_ACCOUNT_LOGIN_TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: CUSTOMER_ACCOUNT_LOGIN_TYPE_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => CUSTOMER_ACCOUNT_LOGIN_TYPES[value],
    },
  },
  [SharedFieldTypes.CHALLENGE_PREFERENCE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: CHALLENGE_PREFERENCE_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => CHALLENGE_PREFERENCES[value],
    },
  },
  [SharedFieldTypes.NOTIFICATION_STATUS]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: NOTIFICATION_STATUS_LIST.map(({ id, label, color }) => ({ value: id, label, color })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => NOTIFICATION_STATUSES[value],
      tag: true,
    },
  },
  [SharedFieldTypes.WEBHOOK]: {
    type: FieldTypes.ASYNC_LIST,
    filterOptions: {
      asyncOptions: {
        query: webhooksQuery,
        formatData: (data) =>
          data?.webhooks?.map((webhook) => ({
            value: webhook.id,
            label: webhook.name,
          })),
        defaultValue: [],
      },
    },
    displayOptions: {},
  },
  [SharedFieldTypes.COMPANY]: {
    type: FieldTypes.ASYNC_LIST,
    filterOptions: {
      asyncOptions: {
        query: merchantCompaniesQuery,
        formatData: (data) =>
          data?.merchantCompanies?.map((c) => ({
            value: c.id,
            label: c.name,
            img: c.theme.icon,
            color: c.theme.secondaryColor,
          })),
      },
      defaultValue: [],
      nullable: false,
    },
    displayOptions: {
      avatar: true,
    },
  },
  [SharedFieldTypes.PROGRAM_MANAGER]: {
    type: FieldTypes.ASYNC_LIST,
    filterOptions: {
      asyncOptions: {
        query: programManagersQuery,
        formatData: (data) =>
          data?.programManagers?.map((pm) => ({
            value: pm.id,
            label: pm.name,
            img: pm.theme.icon,
            color: pm.theme.secondaryColor,
          })),
      },
      defaultValue: [],
      nullable: false,
    },
    displayOptions: {
      image: true,
    },
  },
  [SharedFieldTypes.META_PROGRAM_MANAGER]: {
    type: FieldTypes.ASYNC_LIST,
    filterOptions: {
      asyncOptions: {
        query: metaProgramManagersQuery,
        formatData: (data) =>
          data?.metaProgramManagers?.map((mpm) => ({
            value: mpm.id,
            label: mpm.name,
            img: mpm.theme.icon,
            color: mpm.theme.secondaryColor,
          })),
      },
      defaultValue: [],
      nullable: false,
    },
    displayOptions: {
      image: true,
    },
  },
  [SharedFieldTypes.RECONCILIATION_REASON]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: Object.values({ ...RECONCILIATION_REASONS, ...MATCH_LATER_REASONS }).map(({ id, label }) => ({
        value: id,
        label,
      })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => ({ ...RECONCILIATION_REASONS, ...MATCH_LATER_REASONS })[value],
    },
  },
  [SharedFieldTypes.WITHDRAW_REASON]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: Object.values(WITHDRAW_REASONS).map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    isForbidden: (can) => !can('read', 'matcher-withdraw'),
    displayOptions: {
      getOption: (value) => WITHDRAW_REASONS[value],
    },
  },
  [SharedFieldTypes.RECONCILIATION_TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: RECONCILIATION_TYPE_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => RECONCILIATION_TYPES[value],
    },
  },
  [SharedFieldTypes.INTEGRATION_TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: INTEGRATION_TYPE_LIST.map(({ id, label }) => ({ value: id, label })),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => INTEGRATION_TYPES[value],
    },
  },
  [SharedFieldTypes.LOG_ENTITY_TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: [
        { value: 'ApiKey', label: 'API key' },
        { value: 'List', label: 'List' },
        { value: 'MerchantAccount', label: 'Merchant account' },
        { value: 'MerchantContract', label: 'Merchant contract' },
        { value: 'Order', label: 'Order' },
        { value: 'Register', label: 'Register' },
        { value: 'Rule', label: 'Rule' },
        { value: 'User', label: 'User' },
        { value: 'Webhook', label: 'Notification' },
        { value: 'MerchantCompany', label: 'Merchant company' },
      ],
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) =>
        ({
          ApiKey: { label: 'API key' },
          List: { label: 'List' },
          MerchantAccount: { label: 'Merchant account' },
          MerchantContract: { label: 'Merchant contract' },
          Order: { label: 'Order' },
          Register: { label: 'Register' },
          Rule: { label: 'Rule' },
          User: { label: 'User' },
          Webhook: { label: 'Notification' },
          MerchantCompany: { label: 'MerchantCompany' },
        })[value],
    },
  },
  [SharedFieldTypes.LOG_ENTITY_ID]: {
    type: FieldTypes.CUSTOM,
    displayOptions: {
      customRender: (logId, log) => <Link to={getLinkTo(log)}>{logId}</Link>,
    },
    filterOptions: {
      defaultValue: [],
    },
  },
  [SharedFieldTypes.LOG_UPDATE]: {
    type: FieldTypes.CUSTOM,
    displayOptions: {
      customRender: (_, log) => <UpdateCell log={log} />,
    },
  },
  // Brainpower specific
  AMOUNT_RANGE: {
    type: FieldTypes.LIST,
    displayOptions: {
      getOption: (value) => amountRanges[value],
    },
  },
  NO_DIMENSION: {
    type: FieldTypes.LIST,
    displayOptions: {
      getOption: (value) => (value === 'no_dimension' ? { label: 'No dimension' } : null),
    },
  },
  PERCENT: {
    type: FieldTypes.NUMBER,
    filterOptions: {
      defaultValue: null,
    },
    displayOptions: {},
    defaultSettings: {
      format: NumberFormats.FR,
      unit: 'percent',
    },
  },
  // Checkout
  [SharedFieldTypes.CHECKOUT_TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: Object.values(CHECKOUT_TYPES),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => CHECKOUT_TYPES[value],
    },
  },
  [SharedFieldTypes.TOKEN_TYPE]: {
    type: FieldTypes.LIST,
    filterOptions: {
      options: Object.values(CHECKOUT_TOKEN_TYPES),
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => CHECKOUT_TOKEN_TYPES[value],
    },
  },
};

export const checkFieldMode = (fieldKey, mode) =>
  (mode === MODES.INTERNAL ||
    ![Fields.META_PROGRAM_MANAGER, Fields.NOTIFICATION_META_PROGRAM_MANAGER, Fields.LOG_META_PROGRAM_MANAGER].includes(
      fieldKey,
    )) && // hide Meta PM for non internal
  ([MODES.INTERNAL, MODES.META_PM].includes(mode) ||
    ![Fields.PROGRAM_MANAGER, Fields.NOTIFICATION_PROGRAM_MANAGER, Fields.LOG_PROGRAM_MANAGER].includes(fieldKey)) && // hide PM field for Merchants && PMs
  ([MODES.INTERNAL, MODES.META_PM, MODES.PM].includes(mode) ||
    ![Fields.COMPANY, Fields.NOTIFICATION_COMPANY, Fields.LOG_COMPANY].includes(fieldKey)); // hide COMPANY field for Merchants

export default FieldList.map((fieldKey) => {
  if (!fields[fieldKey]) console.log(fieldKey);
  return fields[fieldKey];
}).reduce(
  (memo, f) => ({
    ...memo,
    [f.key]: {
      ...f,
      ...fieldTypes[f.type],
    },
  }),
  {},
);

export const fieldsByMode = (mode) =>
  FieldList.map((fieldKey) => {
    if (!fields[fieldKey]) console.log(fieldKey);
    return fields[fieldKey];
  })
    .filter((f) => checkFieldMode(f.key, mode))
    .reduce(
      (memo, f) => ({
        ...memo,
        [f.key]: {
          ...f,
          ...fieldTypes[f.type],
        },
      }),
      {},
    );
