import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Space } from 'antd';
import PuzzleIcon from '@2fd/ant-design-icons/lib/Puzzle';
import Column from '../../Common/NavigationContainer/Column/Column';
import NavigationContainer from '../../Common/NavigationContainer/NavigationContainer';
import Elem from '../../Common/NavigationContainer/Column/Elem/Elem';
import styles from './PaymentNetwork.module.scss';
import ServiceProviders from './ServiceProviders/ServiceProviders';
import RoutingRules from './RoutingRules/RoutingRules';
import { useMerchantAccount } from '../merchantAccount.context';
import Page from '../../Common/Page/Page';
import MerchantAccountSelector from '../MerchantAccountSelector/MerchantAccountSelector';
import SiderPage from '../../Common/SiderPage/SiderPage';
import { useCan } from '../../../../contexts/ability.context';
import { formatEndUnderline } from '../../Brainpower/Common/utils';
import SOURCES from '../../../../constants/SOURCES';
import PaymentNetworkSider from './PaymentNetworkSider/PaymentNetworkSider';

export const ContractEditionContext = React.createContext({
  editedContractId: null,
  setEditedContractId: () => undefined,
  isEdited: false,
  setIsEdited: () => undefined,
  isSaving: false,
  setIsSaving: () => undefined,
});
export const CertificateEditionContext = React.createContext({
  editedCertificateId: null,
  setEditedCertificateId: () => undefined,
});

const PaymentNetwork = () => {
  const { selectedMerchantAccount, merchantAccount } = useMerchantAccount();
  const can = useCan();

  const [editedContractId, setEditedContractId] = useState(null);
  const [editedCertificateId, setEditedCertificateId] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [forcedActiveColumns, setForcedActiveColumns] = useState(null);

  useEffect(() => {
    setIsEdited(false);
  }, [editedContractId, editedCertificateId]);

  return (
    <ContractEditionContext.Provider
      value={{
        editedContractId,
        setEditedContractId,
        isEdited: isEdited && can('update', 'merchant_contract'),
        setIsEdited,
        isSaving,
        setIsSaving,
      }}
    >
      <CertificateEditionContext.Provider
        value={{
          editedCertificateId,
          setEditedCertificateId,
        }}
      >
        <Page
          title={
            <Space style={{ fontSize: 20 }}>
              <PuzzleIcon />
              {formatEndUnderline('Payment network')}
            </Space>
          }
          headerContent={<MerchantAccountSelector />}
          centerHeader
        >
          <SiderPage
            defaultOpen
            isSiderHidden={!editedContractId && !editedCertificateId}
            siderContent={
              <PaymentNetworkSider
                merchantContractId={editedContractId}
                certificateId={editedCertificateId}
                setForcedAtiveColumns={setForcedActiveColumns}
              />
            }
            hideToggle
          >
            <div
              className={classnames(styles.paymentNetwork, {
                [styles.noMerchantAccount]: !selectedMerchantAccount,
              })}
            >
              {!selectedMerchantAccount && (
                <div className={styles.noMerchantMessage}>Please select a merchant account in the selector above</div>
              )}
              <NavigationContainer
                columnSteps={{
                  md: 5,
                  sm: 3,
                }}
                navigationId="pn"
                forcedActiveColumns={forcedActiveColumns}
              >
                <Column id="root">
                  {can('read', 'merchant_contract') && (
                    <Elem
                      id="serviceProviders"
                      key="serviceProviders"
                      title="Service Providers"
                      value="Payment, Risk & Authentication"
                      subColumn={<ServiceProviders />}
                    />
                  )}
                  {can('read', 'rule') && merchantAccount?.source === SOURCES.STACK && (
                    <Elem
                      id="routingMatrix"
                      key="routingMatrix"
                      title="Routing Matrix"
                      value="Configurations & default settings"
                      subColumn={<RoutingRules />}
                    />
                  )}
                </Column>
              </NavigationContainer>
            </div>
          </SiderPage>
        </Page>
      </CertificateEditionContext.Provider>
    </ContractEditionContext.Provider>
  );
};

export default PaymentNetwork;
